<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
              :rules="[
                this.$validators.string.required,
                this.$validators.string.cpf
              ]"
              v-mask="'###.###.###-##'"
              label="CPF"
              v-model="value.login"/>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
              label="Data de Nascimento"
              v-mask="'##/##/####'"
              v-model="value.password"
              :rules="[
                  this.$validators.string.required,
                  this.$validators.string.notEmpty
              ]"
             />
    </v-col>  
  </v-row>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";

export default {
  name: "CadastreSeForm",
  components: {SearchBox},
  props: {
    value: {
      type: Object
    },
  },
}
</script>

<style scoped>

</style>