"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.update = exports.createPorToken = exports.createClienteLogado = exports.create = exports.findOne = exports.findInscricaoPorToken = exports.findAllInscricaoAtiva = exports.findAllInscricoesAtivas = exports.findAllClienteLogado = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('CompInscricaoCompeticao', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findAllClienteLogado(page = 1, search) {
    return core_1.get('CompInscricaoCompeticao', 'listaClienteLogado', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllClienteLogado = findAllClienteLogado;
function findAllInscricoesAtivas(page = 1, search) {
    return core_1.get('CompInscricaoCompeticao', 'listaIncricoesAtivas', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllInscricoesAtivas = findAllInscricoesAtivas;
function findAllInscricaoAtiva(page = 1, search) {
    return core_1.get('CompInscricaoCompeticao', 'listaInscricaoAtiva', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllInscricaoAtiva = findAllInscricaoAtiva;
function findInscricaoPorToken(tokenInscricao, idCompEvento) {
    return core_1.get('CompInscricaoCompeticao', 'listaPorToken', {
        tokenInscricao,
        idCompEvento
    })
        .then(({ dados, token }) => {
        return {
            token,
            current: dados[0]
        };
    });
}
exports.findInscricaoPorToken = findInscricaoPorToken;
function findOne(id) {
    return core_1.get('CompInscricaoCompeticao', 'detalhe', { id });
}
exports.findOne = findOne;
function create(data) {
    return core_1.post('CompInscricaoCompeticao', 'cadastro', data);
}
exports.create = create;
function createClienteLogado(data) {
    return core_1.post('CompInscricaoCompeticao', 'cadastroClienteLogado', data);
}
exports.createClienteLogado = createClienteLogado;
function createPorToken(tk, data) {
    return core_1.post('CompInscricaoCompeticao', 'cadastroPorToken', Object.assign({ tk }, data));
}
exports.createPorToken = createPorToken;
function update(id, data) {
    return core_1.put('CompInscricaoCompeticao', 'update', Object.assign({ id }, data));
}
exports.update = update;
function remove(id) {
    return core_1.del('CompInscricaoCompeticao', 'exclui', { id });
}
exports.remove = remove;
